
import { useNavigate } from "react-router-dom";
import { AlbumCreateForm } from "../ui-components";

const AlbumCreatePage = () => {
    const navigate = useNavigate();
    return (
        <>
            <h1>New Album</h1>
            <div className="mx-auto max-w-md">
                <AlbumCreateForm
                    onSuccess={() => {
                        navigate("/admin", { replace: true })
                    }}
                    onError={(error) => {
                        console.log(error)
                    }}
                />
            </div>
        </>
    )
}

export const action = async ({ request }) => {

}

export default AlbumCreatePage;
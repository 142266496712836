import { Outlet } from "react-router-dom";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import { Authenticator } from '@aws-amplify/ui-react';

const Admin = ({ signOut, user }) => {
    return (
        <Authenticator variation="modal" hideSignUp={true}>
            <div className="App">
                <NavBar />
                <div className="min-h-screen">
                    <div className="max-w-6xl mx-auto p-3">
                        <div className="flex flex-col justify-start items-start">
                            {/* <h1 className="text-xl py-3">Welcome</h1> */}
                        </div>
                        <Outlet />
                    </div>
                </div>
                <Footer />
            </div>
        </Authenticator>
    )
}

export default Admin;
import { API, DataStore } from "aws-amplify";
import * as queries from '../graphql/queries';
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Album } from "../models";
import { StorageImage } from "@aws-amplify/ui-react-storage";
import PHImage from "../images/ph-image.png"

function AlbumViewPage() {
    const [album, setAlbum] = useState([]);
    const [imageKeys, setImageKeys] = useState([]);
    const navigate = useNavigate();
    const location = useLocation();
    let { id } = useParams();

    async function deleteAlbum(id, version) {
        try {
            const toDelete = await DataStore.query(Album, id);
            if (toDelete) {
                DataStore.delete(toDelete);
            }
            navigate("/admin", { replace: true })

        } catch (err) {
            console.log(err)
        }
    }

    async function getAlbum(id) {
        try {
            const albumData = await API.graphql({
                query: queries.getAlbum,
                variables: { id: id }
            })
            const album = albumData.data.getAlbum
            setAlbum(album)
            getImages(album)
        } catch (err) {
            console.log(err)
        }
    }

    // get imageURL of album
    async function getImages(album) {
        const images = album.image_urls
        setImageKeys(images)
    }

    useEffect(() => {
        getAlbum(id)
    }, [])

    return (
        <>
            <div className="max-w-md mx-auto">
                <h1 className="text-lg font-bold text-justify px-2 py-3">{album.title}</h1>
                <p className="text-justify py-2 px-2">{album.description}</p>
                {
                    imageKeys.map((image, index) => {
                        return (
                            <div className="rounded-md" key={index}>
                                <StorageImage alt={`album-${index}`} imgKey={image} fallbackSrc={PHImage} accessLevel="public" />
                            </div>
                        )
                    })
                }
                {
                    location.pathname.includes("admin") &&
                    <button
                        className="text-red-500 py-2 px-3 rounded-md text-lg hover:text-white hover:bg-red-500"
                        onClick={() => { deleteAlbum(album.id, album._version) }}
                    >
                        Delete
                    </button>
                }
            </div>
        </>
    )
}

export default AlbumViewPage;
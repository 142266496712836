import { Button, useAuthenticator } from "@aws-amplify/ui-react";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DataStore } from "aws-amplify";
import { NavLink, useLocation } from "react-router-dom";
import LogoComponent from "./LogoComponent";

function NavBar() {

    const { user, signOut } = useAuthenticator(context => [context.user]);
    const location = useLocation();

    async function signUserOut() {
        await DataStore.clear();
        signOut()
    }

    const links_url = [
        { name: "Projects", url: "/projects" },
        { name: "Gallery", url: "/gallery" },
        { name: "Our Team", url: "/our_team" },
        { name: "Admin", url: "/admin" }
    ]

    const admin_url = [
        { name: "Admin", url: "/admin" },
    ]

    function showSideNav(e, type) {
        e.preventDefault();
        const element = document.getElementById(type)
        element.style.display = 'block';
    }

    function hideSideNav(e, type) {
        // e.preventDefault();
        const element = document.getElementById(type)
        element.style.display = '';
    }

    return (
        <nav className="px-2">
            <div className="mx-auto max-w-6xl">
                <div className="flex items-center justify-between text-xl font-extrabold py-3">
                    <NavLink to='/'>
                        <div className="flex items-center justify-center">
                            <LogoComponent />
                            <span className="text-[#f44336] inline-block">Grafted In Shelter</span>
                        </div>
                    </NavLink>
                    <div className="hidden text-lg md:inline-block text-[#f44336]">
                        {
                            location.pathname.includes('admin') ?
                                admin_url.map((url, i) => {
                                    return (
                                        <NavLink key={i} className='px-2' to={url.url}>{url.name}</NavLink>
                                    )
                                })
                                :
                                links_url.map((url, i) => {
                                    return (
                                        <NavLink key={i} className='px-2' to={url.url}>{url.name}</NavLink>
                                    )
                                })
                        }
                        {
                            user ? <Button color="#f44336" onClick={signUserOut}>Sign Out</Button> : ""
                        }

                    </div>
                    <div className="md:hidden">
                        <FontAwesomeIcon icon={faBars} className="md:hidden text-[#f44336]"
                            id="menu-icon"
                            onClick={(e) => { showSideNav(e, "sidenav-menu") }}
                        />
                        <div id="sidenav-menu" className="top-0 left-0 fixed w-full hidden h-full p-3 bg-cyan-600 z-10 overflow-x-hidden transition duration-500 ease-in">
                            <p className="text-3xl absolute right-5" onClick={(e) => { hideSideNav(e, "sidenav-menu") }}>&times;</p>
                            <div className="flex flex-col justify-center items-center py-10">
                                <NavLink className='px-2 py-3' to="/" reloadDocument>Home</NavLink>
                                {
                                    location.pathname.includes('admin') ?
                                        admin_url.map((url, i) => {
                                            return (
                                                <NavLink key={i} className='px-2 py-3' to={url.url} reloadDocument>{url.name}</NavLink>
                                            )
                                        }) :
                                        links_url.map((url, i) => {
                                            return (
                                                <NavLink key={i} className='px-2 py-3' to={url.url} reloadDocument>{url.name}</NavLink>
                                            )
                                        })
                                }
                                {
                                    user ? <Button color="#f44336" onClick={signOut}>Sign Out</Button> : ""
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    )
}

export default NavBar;
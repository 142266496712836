import FounderBoth from "../images/founder-both.jpg"
import Volunteers from "../images/volunteers.jpeg"

function Founder() {
    return (
        <>
            <section id="founder" className="min-h-screen">
                <div className="max-w-6xl mx-auto">
                    <div className="flex py-6">
                        <div className="p-2">
                            <h1 className="text-2xl text-justify p-2 text-[#f44336] font-bold">Founders</h1>
                            <img className="rounded-lg float-left m-3" src={FounderBoth} alt="" />
                            <p className="text-justify">
                                Eugene and Benonia Laryea were born into Presbyterian and Methodist homes respectively and currently worship with Harvest Chapel International, Community 18 Tema where Eugene has been the branch pastor since 2004.
                                Benonia leads the women’s ministry at their branch and also does several other charity works within church and with other NGOs.
                                Professionally Eugene works as the Works Manager of Ghacem Ghana Ltd, a subsidiary of Heidelberg Cement International, he holds a B.Sc. in Mechanical Engineering and an MBA in Management Information Systems.
                                Benonia worked until June 2015 with Airtel Ghana Ltd for seventeen years, holding various roles. She Holds a Bachelor’s degree in Economics and Law and an Executive MBA. Aside her work as a Philanthropist she also manages a private consultancy that does Company Representation
                                The couple are blessed with three children Charise, Eugene David and Delise.
                            </p>
                        </div>
                    </div>
                    <div className="p-2">
                        <h1 className="text-2xl text-justify p-2 text-[#f44336] font-bold">Partners</h1>
                        <p className="text-justify">We are blessed with Partners, made up of family and friends, from all over the globe who supports in cash and in kind the various Grafted In Shelter Missions all year round.</p>
                    </div>
                    <div className="p-2 min-h-[500px]">
                        <h1 className="text-2xl text-justify p-2 text-[#f44336] font-bold">Volunteers</h1>
                        <img className="rounded-lg float-left m-3" src={Volunteers} alt="" />
                        <p className="text-justify">Faithful and loyal young people who take time off their own schedules to teach at our weekly Goodnews Club meetings every Saturday; engaging the children in Bible lessons and fun activities to draw their little hearts to Christ.
                            The Laryea children also play vital roles in the smooth running of weekly meetings and other activities.
                        </p>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Founder;
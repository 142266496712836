import AlbumViewPage from "./AlbumViewPage";

function GalleryViewPage() {
    return (
        <>
            <div className="min-h-screen">
                <AlbumViewPage />
            </div>
        </>
    )
}

export default GalleryViewPage;
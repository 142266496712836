import { NavLink } from "react-router-dom";

function Footer() {
    return (
        <nav className="bg-[#01585b] px-3">
            <div className="mx-auto max-w-6xl text-white py-5">
                <div className="grid grid-cols-1 md:grid-cols-3 p-2">
                    <div className="flex flex-col place-items-start">
                        <NavLink to='/' className='py-1'>Grafted In Shelter</NavLink>
                        <p>All rights reserved - 2023</p>
                    </div>
                    <div className="flex flex-col place-items-start">
                        <NavLink to='/founders' className='py-1'>Founders</NavLink>
                        <NavLink to='/projects' className='py-1'>Projects</NavLink>
                        <NavLink to='/gallery' className='py-1'>Gallery</NavLink>
                    </div>
                    <div className="flex flex-col place-items-start">
                        <p className="pr-0">+233 244330044 / +233 266000425</p>
                        <a href="mailto:graftedinshelter@gmail.com?subject=Contact from Website">Email Us: graftedinshelter@gmail.com</a>
                        <p className="pr-0">Location: PR/EA/128, Afienya East GN-0860-6943</p>
                    </div>
                </div>
            </div>
        </nav>
    )
}

export default Footer;
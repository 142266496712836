import WelcomeImg1 from '../images/welcome_pic_1.jpeg'
import WelcomeImg3 from '../images/welcome_pic_3.jpeg'
import Logo from '../images/logo.png'

function Home() {
    return (
        <>
            <section id='header' className='min-h-screen'>
                <div className='mx-auto max-w-6xl bg-white flex flex-col justify-center items-center p-3'>
                    <div className="py-8 flex flex-col justify-center items-center">
                        <div className='h-40 w-40 rounded-lg'>
                            <img src={Logo} alt="" />
                        </div>
                        <h1 className='text-7xl font-extrabold text-center'>Grafted In Shelter</h1>
                        <h2 className='py-3 text-[#f44336] font-bold text-3xl text-center'>A Place of Restoration</h2>
                    </div>
                    <div className='columns-1 gap-5 md:columns-2'>
                        <div className="p-2">
                            <img className='rounded-xl shadow' src={WelcomeImg1} alt="" />
                        </div>
                        <div className="p-2">
                            <img className='rounded-xl shadow' src={WelcomeImg3} alt="" />
                        </div>
                    </div>
                    <div className='px-3 py-8 md:px-14'>
                        <p className='text-xl'>
                            <span className='text-[#f44336] font-bold'>Grafted In Shelter</span> is a non-governmental organisation (NGO) registered in Ghana in June 2015, with a mission to establish community based Good News Clubs for children of all ages and also build a half way home for the destitute.
                            <span className='text-[#f44336] font-bold'>Our name</span> is inspired by <span className='text-[#f44336] font-bold'>Romans 11:17</span> which paraphrased says we have been grafted in through Christ to partake of God&apos;s inheritance.
                            Our Vision is to restore children, teens and the destitute to a fulfilled life in <span className='text-[#f44336] font-bold'>Jesus Christ</span>.
                        </p>
                    </div>
                </div>
            </section>
            <section id='main-mission' className='bg-gradient-to-r from-[#f44336] to-[#f1aaa5]'>
                <div className="mx-auto max-w-6xl">
                    <div className="flex flex-wrap text-xl text-white py-10">
                        <div className="p-2 md:basis-1/2 basis-full">
                            <h1 className='font-bold'>Mission</h1>
                            <ul className="list-none">
                                <li className='text-justify p-2'>Community based Good News clubs where children of all ages are kept occupied for a few hours weekly with Bible studies, fun activities, giveaways etc.</li>
                                <li className='text-justify p-2'>Collaborations with other Charity Organizations for Children and Youth</li>
                                <li className='text-justify p-2'>A halfway home shelter of love and warmth to restore and empower the destitute back to full life in Christ.</li>
                            </ul>
                        </div>
                        <div className="p-2 md:basis-1/2  basis-full">
                            <h1 className='font-bold'>Our Core Values</h1>
                            <p>Compassion, Love, Fruit bearing, Faith, Excellence, Integrity</p>
                            <br />
                            <h1 className='font-bold'>Ongoing Missions</h1>
                            <ul className="list-none">
                                <li className='text-justify p-2'>Afienya Goodnews Club - Since first meeting on 2nd July 2016; Weekly Goodnews Bible Club meetings sits an average of 130+ children</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            <section id="collaborations" >
                <div className='mx-auto max-w-6xl py-10'>
                    <div className='pt-5'>
                        <h1 className='font-bold text-xl'>Collaborations</h1>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-3 py-5">
                        <div className="flex flex-col p-2 place-items-center">
                            <div className='w-full bg-[#01585b] border border-cyan-800 rounded-md text-white text-lg'>
                                <p className='p-3'>
                                    Educational Sponsorships and other charity drives with
                                </p>
                                <p className='p-3 text-xl text-white font-bold'>
                                    Save Them Young Orphanage
                                </p>
                            </div>
                        </div>
                        <div className="flex flex-col p-2">
                            <div className='w-full bg-[#01585b] border border-cyan-800 rounded-md text-white text-lg'>
                                <p className='p-3'>
                                    Periodic Bible lessons and other charity drives with
                                </p>
                                <p className='p-3 text-xl text-white font-bold'>
                                    Tetteh Ocloo Deaf School
                                </p>
                            </div>
                        </div>
                        <div className="flex flex-col p-2">
                            <div className='w-full bg-[#01585b] border border-cyan-800 rounded-md text-white text-lg'>
                                <p className='p-3'>
                                    Charity drives for Single Mothers and children with
                                </p>
                                <p className='p-3 text-xl text-white font-bold'>
                                    Mahlef Foundation
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="donations" className='bg-[#01585b] py-10'>
                <div className='mx-auto max-w-6xl  text-white'>
                    <div className='pt-5'>
                        <h1 className='font-bold text-xl p-2'>Love Donation Channels:</h1>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-3 py-5">
                        <div className="flex flex-col p-2">
                            <div className='text-lg'>
                                <p className='p-3'>
                                    MTN Mobile Money
                                </p>
                                <p className='p-3 text-xl text-white font-bold'>
                                    0552957010 / 0557449678
                                </p>
                                <p className='px-3'>
                                    Registered names: Grafted In Shelter / Benonia Ohui Laryea
                                </p>
                            </div>
                        </div>
                        <div className="flex flex-col p-2">
                            <div className='text-lg'>
                                <p className='p-3'>
                                    Bank Account
                                </p>
                                <div className='p-3'>
                                    <p className='text-xl text-white font-bold'>
                                        Tema Main Branch
                                    </p>
                                    <p className='text-xl text-white font-bold'>
                                        1441001554268
                                    </p>
                                    <p className=''>
                                        Ecobank Ghana Limited
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Home;
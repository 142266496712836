import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import ErrorPage from './pages/ErrorPage';
import Home from './pages/Home';
import Project from './pages/Project';
import Founder from './pages/Founder';
import GalleryPage from './pages/Gallery';


import { Authenticator, ThemeProvider } from "@aws-amplify/ui-react";


import "@aws-amplify/ui-react/styles.css";
import { studioTheme } from "./ui-components";
import Admin from './Admin';
import AdminPage from './pages/AdminPage';
import AlbumsPage, { loader as AlbumsLoader } from './pages/AlbumsPage';
import AlbumCreatePage from './pages/AlbumCreatePage';

import { Amplify, AuthModeStrategyType } from 'aws-amplify';
import awsconfig from './aws-exports';
import AlbumViewPage from './pages/AlbumViewPage';
import GalleryViewPage from './pages/GalleryViewPage';
Amplify.configure({
  ...awsconfig,
  DataStore: {
    authModeStrategyType: AuthModeStrategyType.MULTI_AUTH
  }
});

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <Home />
      },
      {
        path: "/projects",
        element: <Project />
      },
      {
        path: "/our_team",
        element: <Founder />
      },
      {
        path: "/gallery",
        element: <GalleryPage />,
        loader: AlbumsLoader,
      },
      {
        path: "/gallery/:id",
        element: <GalleryViewPage />
      }
    ]
  },
  {
    path: "/admin",
    element: <Admin />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "",
        element: <AdminPage />
      },
      {
        path: "albums",
        element: <AlbumsPage />,
        loader: AlbumsLoader,
      },
      {
        path: "albums/create",
        element: <AlbumCreatePage />,
        action: AlbumCreatePage.action
      },
      {
        path: "albums/:id",
        element: <AlbumViewPage />,
        loader: ({ params }) => {
          return params.id
        }
      }
    ]
  }
])

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Authenticator.Provider>
      <ThemeProvider theme={studioTheme}>
        <RouterProvider router={router} />
      </ThemeProvider>
    </Authenticator.Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';



const { Album, Partner } = initSchema(schema);

export {
  Album,
  Partner
};
import project1 from "../images/project1.jpeg"
import project2 from "../images/project2.jpeg"
import project3 from "../images/project3.jpeg"


function Project() {
    return (
        <>
            <section id="project" className="min-h-screen">
                <div className="mx-auto max-w-6xl">
                    <div className="w-full">
                        <img className="w-full" src={project1} alt="" />
                    </div>
                    <h1 className="text-2xl text-justify p-2 text-[#f44336] font-bold">Motivation</h1>
                    <p className="text-l text-justify indent-3 py-3 px-2">
                        In many places all around the world, one will usually come across a person who finds themselves in a distressing life situation and thus need a place to crush for some time, while getting their life back together again. Having been Christians for close to 23 years, we have come across many people like this from all walks of life and especially those who find their way to church. The fact remains that in many instances taking such a person home is not the most ideal of situations but having a place where the person can be given a home of love and also rehabilitated and counselled back to their feet is the best.
                        This is therefore the purpose for which Grafted In Shelter is being set up, to offer a place of shelter and restoration to persons who find themselves in life crisis and situations that entangle them.
                    </p>
                    <div className='columns-1 gap-5 md:columns-2'>
                        <div className="p-2">
                            <img className='rounded-xl shadow' src={project2} alt="" />
                        </div>
                        <div className="p-2">
                            <img className='rounded-xl shadow' src={project3} alt="" />
                        </div>
                    </div>
                    <h1 className="text-2xl text-justify p-2 text-[#f44336] font-bold">Impact</h1>
                    <p className="text-justify p-2">
                        Restoration and empowerment to persons who are distressed and in life crisis will empower one individual at a time through Christ to make our society a better place.
                        “Distressed” and “life crisis” here refers to but not limited to the following:
                    </p>
                    <ul className="list-none text-justify indent-3 p-2">
                        <li>Pregnancy out of wedlock</li>
                        <li>Family rejection</li>
                        <li>Challenges with Sexual sins</li>
                        <li>Marital and Sexual Abuse</li>
                        <li>Fallen on hard times</li>
                    </ul>
                    <h1 className="text-2xl text-justify p-2 text-[#f44336] font-bold">Facilities</h1>
                    <p className="text-justify p-2">
                        Working Drawings still work in progress, total cost will be updated soon. Per the design, plan is to build a two storey building with the following facilities:
                    </p>
                    <ul className="list-none text-justify indent-3 p-2">
                        <li>16 Bed Family rooms</li>
                        <li>46 bed dormitories</li>
                        <li>Library and training</li>
                        <li>Counselling, Sick Bay and Prayers</li>
                        <li>Apartment for coordinators</li>
                        <li>Administrative office</li>
                    </ul>
                </div>
            </section >
        </>
    )
}

export default Project;
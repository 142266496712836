import { Link } from "react-router-dom";

const AdminPage = () => {
    return (
        <>
            <section className="">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
                    <div className="flex flex-col border border-cyan-500 rounded-md items-start p-2">
                        <h1 className=" font-bold">Gallery</h1>
                        <p className="text-slate-500">Use this section to add, update and delete albums in the gallery</p>
                        <br />
                        <Link className="text-blue-500 hover:text-blue-800 hover:font-bold" to="albums">Manage Albums</Link>
                    </div>
                </div>
            </section>
        </>
    )
}

export default AdminPage;
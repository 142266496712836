import { StorageImage } from "@aws-amplify/ui-react-storage";
import { useNavigate } from "react-router-dom";
import PHImage from "../images/ph-image.png"

function AlbumComponent({ album }) {
    const navigate = useNavigate();

    return (
        <>
            <div>
                <div className="flex flex-col" onClick={() => navigate(`${album.id}`, { relative: true })}>
                    <div className="" id="album-index-image">
                        <StorageImage alt={album.title} imgKey={album.index_image} fallbackSrc={PHImage} accessLevel="public" />
                        {/* <img className="h-52 object-contain" src={album.index_image} alt="" /> */}
                    </div>
                    <div className="items-start py-3">
                        <p className="text-left">{album.title}</p>
                    </div>
                </div >
            </div>
        </>
    )
}

export default AlbumComponent;
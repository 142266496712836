import Logo from '../images/logo.png'

function LogoComponent() {
    return (
        <div className='flex items-center justify-center'>
            <div className='h-16 w-14 rounded-lg'>
                <img src={Logo} alt="" />
            </div>
        </div>
    )
}

export default LogoComponent;
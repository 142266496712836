import AlbumsPage from "./AlbumsPage";

function GalleryPage() {
    return (
        <>
            <section className="min-h-screen">
                <div className="mx-auto max-w-6xl p-3">
                    <AlbumsPage />
                </div>
            </section>

        </>
    )
}

export default GalleryPage;
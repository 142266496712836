
import { Await, Link, defer, useLoaderData, useLocation } from "react-router-dom";
import { API } from 'aws-amplify';
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import * as queries from '../graphql/queries';
import { Suspense} from "react";
import AlbumComponent from "../components/AlbumComponent";

const AlbumsPage = () => {
    const location = useLocation();
    const { albums } = useLoaderData();
    // const [albumList, setAlbums] = useState([]);

/*    useEffect(() => {
        setAlbums(albums)
    }, [albums])*/

    return (
        <>
            <section id="albums_page" className="">
                <div className="flex flex-row items-center justify-between pb-3">
                    <h1>Albums</h1>
                    {
                        location.pathname.includes("admin") &&
                        <Link
                            className="border border-cyan-700 p-2 rounded-md hover:text-white hover:bg-cyan-700"
                            to="create">
                            Create New Album
                        </Link>
                    }
                </div>
                <hr />
                <Suspense fallback={<p>Loading albums...</p>}>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-3 py-3">
                        <Await
                            resolve={albums}
                            errorElement={
                                <p>Error loading albums. Tray again later</p>
                            }
                        >
                            {(albums) => (
                                albums.map((album, index) => {
                                    return (
                                        <AlbumComponent key={index} album={album} />
                                    )
                                })
                            )}
                        </Await>
                    </div >
                </Suspense >
            </section >
        </>
    )
}

export async function loader() {
    const variables = {
        filter: {
            _deleted: {
                ne: true
            }
        }
    };
    try {
        const data = await API.graphql({
            query: queries.listAlbums,
            variables: variables,
            authMode: GRAPHQL_AUTH_MODE.API_KEY
        });
        const albums = data.data.listAlbums.items
        albums.sort((a, b) => Date.parse(b.createdAt) - Date.parse(a.createdAt))
        albums.map(async (album) => {
            const images = album.image_urls
            images.map(async (image, i) => {
                try {
                    if (i === 0) {
                        album.index_image = image
                    }
                } catch (err) {
                    console.log(err)
                }
            })
        })
        return defer({albums: albums})
    } catch (e) {
        console.log(e)
    }


}

export default AlbumsPage;
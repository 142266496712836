/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_appsync_graphqlEndpoint": "https://zzvy3jyr2jgmlchy25ttmfwdoy.appsync-api.eu-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-central-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-zs5bmuwjjjdjdfmirakmjqe2be",
    "aws_cognito_identity_pool_id": "eu-central-1:f57012c4-d538-4b65-834a-a2706b13d9a8",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_msb8nK1lu",
    "aws_user_pools_web_client_id": "4vhep47vuhkeseof1viu8kfui7",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_dynamodb_all_tables_region": "eu-central-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "grafted-main",
            "region": "eu-central-1"
        }
    ],
    "aws_user_files_s3_bucket": "graftedinshelter-bucket114056-main",
    "aws_user_files_s3_bucket_region": "eu-central-1"
};


export default awsmobile;
